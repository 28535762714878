<template>
  <div class=" relative mx-auto">
    <div class=" mx-auto">
      <slot>

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'adminlogin',
}
</script>